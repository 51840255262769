import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Route, RouteComponentProps } from "react-router-dom";
import {
  ActionButtonWithRoute,
  Icon,
  IconName,
  VacationRentalNewTag,
} from "halifax";
import {
  PATH_FLIGHTS,
  PATH_HOTELS,
  PATH_CARS,
  PATH_PREMIER_COLLECTION,
  PATH_PACKAGES,
  PATH_STAYS,
} from "../../utils/urlPaths";
import "./styles.scss";
import clsx from "clsx";
import { LandingPageType } from "../MobileLandingPage";
import {
  useExperiments,
  getExperimentVariantCustomVariants,
  MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT,
  MOBILE_HOMESCREEN_REDESIGN_VARIANTS,
  MOBILE_HOMESCREEN_REDESIGN_V3,
  AVAILABLE,
  getExperimentVariant,
  PREMIER_COLLECTION_EXPERIMENT,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS,
  CONTROL,
  LC_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_PREMIUM_CARDHOLDERS_VARIANTS,
  VR_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
  PACKAGES_EXPERIMENT,
  PACKAGES_EXPERIMENT_VARIANTS,
  SELF_FUNDED,
  NOT_SELF_FUNDED,
  LUXURY_VACATION_RENTAL_FUNNEL_LAUNCH,
  STAYS_HOMEPAGE,
} from "../../context/experiments";

export interface MobileLandingPageProps {
  type?: LandingPageType;
}
const LandingPageNavigation = ({ type }: MobileLandingPageProps) => {
  const expState = useExperiments();
  const mobileHomeScreenVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT,
    MOBILE_HOMESCREEN_REDESIGN_VARIANTS
  );

  const isMobileHomeScreenVariant3 =
    mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V3;

  const isPremierCollectionEnabled =
    getExperimentVariant(
      expState.experiments,
      PREMIER_COLLECTION_EXPERIMENT
    ) === AVAILABLE;

  const LCForNonPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const isLCForNonPremiumCardHoldersEnabled =
    LCForNonPremiumCardholderVariant !== CONTROL;

  const LCForPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const isLCForPremiumCardHoldersEnabled =
    LCForPremiumCardholderVariant !== CONTROL;

  const isVRForNonPremiumCardHoldersEnabled =
    getExperimentVariant(
      expState.experiments,
      VR_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT
    ) === AVAILABLE;

  const packagesExperimentVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    PACKAGES_EXPERIMENT,
    PACKAGES_EXPERIMENT_VARIANTS
  );

  const vacationFunnelLaunchEnabled =
    getExperimentVariant(
      expState.experiments,
      LUXURY_VACATION_RENTAL_FUNNEL_LAUNCH
    ) === AVAILABLE;

  const isPackagesExperiment = [SELF_FUNDED, NOT_SELF_FUNDED].includes(
    packagesExperimentVariant
  );

  const staysHomepageEnabled =
    getExperimentVariant(expState.experiments, STAYS_HOMEPAGE) === AVAILABLE;

  const getSearchButtonText = (type: LandingPageType) => {
    if (type === LandingPageType.PREMIUM_STAYS) {
      return isLCForPremiumCardHoldersEnabled ||
        isVRForNonPremiumCardHoldersEnabled
        ? "premium stays"
        : isLCForNonPremiumCardHoldersEnabled
        ? "lifestyle hotels"
        : "premier hotels";
    } else {
      return type;
    }
  };

  return (
    <>
      <Box
        className={clsx("landing-page-buttons", {
          "pc-enabled":
            (isPremierCollectionEnabled ||
              isLCForNonPremiumCardHoldersEnabled) &&
            isMobileHomeScreenVariant3,
          "redesign-v3-enabled": isMobileHomeScreenVariant3,
        })}
      >
        {isMobileHomeScreenVariant3 && (
          <Route
            render={(browserRouterProps: RouteComponentProps) => (
              <ActionButtonWithRoute
                {...browserRouterProps}
                className={clsx("full-width-landing-button", {
                  hotels: type === "hotels" || type === "stays",
                })}
                message={
                  <Box className="landing-button-content-wrapper">
                    {isMobileHomeScreenVariant3 && (
                      <Icon name={IconName.HotelFunnelIcon} />
                    )}
                    <p>{staysHomepageEnabled ? "Stays" : "Hotels"}</p>
                  </Box>
                }
                disabled={false}
                onClick={() =>
                  staysHomepageEnabled
                    ? browserRouterProps.history.push(PATH_STAYS)
                    : browserRouterProps.history.push(PATH_HOTELS)
                }
                ariaLabelText={staysHomepageEnabled ? "Stays" : "Hotels"}
              ></ActionButtonWithRoute>
            )}
          />
        )}
        <Route
          render={(browserRouterProps: RouteComponentProps) => (
            <ActionButtonWithRoute
              {...browserRouterProps}
              className={clsx("full-width-landing-button", {
                flights: type === "flights",
              })}
              message={
                <Box className="landing-button-content-wrapper">
                  {isMobileHomeScreenVariant3 && (
                    <Icon name={IconName.FlightFunnelIcon} />
                  )}
                  <p>Flights</p>
                </Box>
              }
              disabled={false}
              onClick={() => browserRouterProps.history.push(PATH_FLIGHTS)}
              ariaLabelText="Flights"
            ></ActionButtonWithRoute>
          )}
        />
        {!isMobileHomeScreenVariant3 && (
          <Route
            render={(browserRouterProps: RouteComponentProps) => (
              <ActionButtonWithRoute
                {...browserRouterProps}
                className={clsx("full-width-landing-button", {
                  hotels: type === "hotels",
                })}
                message={
                  <Box className="landing-button-content-wrapper">
                    {isMobileHomeScreenVariant3 && (
                      <Icon name={IconName.HotelFunnelIcon} />
                    )}
                    <p>{staysHomepageEnabled ? "Stays" : "Hotels"}</p>
                  </Box>
                }
                disabled={false}
                onClick={() =>
                  staysHomepageEnabled
                    ? browserRouterProps.history.push(PATH_STAYS)
                    : browserRouterProps.history.push(PATH_HOTELS)
                }
                ariaLabelText={staysHomepageEnabled ? "Stays" : "Hotels"}
              ></ActionButtonWithRoute>
            )}
          />
        )}
        <Route
          render={(browserRouterProps: RouteComponentProps) => (
            <ActionButtonWithRoute
              {...browserRouterProps}
              className={clsx("full-width-landing-button", {
                cars: type === "cars",
              })}
              message={
                <Box className="landing-button-content-wrapper">
                  {isMobileHomeScreenVariant3 && (
                    <Icon name={IconName.CarFunnelIcon} />
                  )}
                  <p>Cars</p>
                </Box>
              }
              disabled={false}
              onClick={() => browserRouterProps.history.push(PATH_CARS)}
              ariaLabelText="Cars"
            ></ActionButtonWithRoute>
          )}
        />
        {(isPremierCollectionEnabled || isLCForNonPremiumCardHoldersEnabled) &&
          isMobileHomeScreenVariant3 && (
            <Route
              render={(browserRouterProps: RouteComponentProps) => (
                <ActionButtonWithRoute
                  {...browserRouterProps}
                  className={clsx("full-width-landing-button", {
                    "premier-collection": type === "premium-stays",
                    "lifestyle-collection":
                      type === "premium-stays" &&
                      isLCForNonPremiumCardHoldersEnabled,
                  })}
                  message={
                    <Box className="landing-button-content-wrapper">
                      {isMobileHomeScreenVariant3 &&
                      isLCForPremiumCardHoldersEnabled ? (
                        <Icon name={IconName.StarIconWithLines} />
                      ) : (
                        <Icon name={IconName.HotelFunnelIcon} />
                      )}
                      {(() => {
                        if (
                          isLCForPremiumCardHoldersEnabled ||
                          isVRForNonPremiumCardHoldersEnabled
                        )
                          return (
                            <>
                              <p>Premium Stays</p>
                              <VacationRentalNewTag
                                enabled={vacationFunnelLaunchEnabled}
                              />
                            </>
                          );
                        if (isLCForNonPremiumCardHoldersEnabled)
                          return <p>Lifestyle Collection</p>;

                        return <p>Premier Collection</p>;
                      })()}
                    </Box>
                  }
                  disabled={false}
                  onClick={() =>
                    browserRouterProps.history.push(PATH_PREMIER_COLLECTION)
                  }
                  ariaLabelText="Premium Collection"
                ></ActionButtonWithRoute>
              )}
            />
          )}
        {isPackagesExperiment && (
          <Route
            render={(browserRouterProps: RouteComponentProps) => (
              <ActionButtonWithRoute
                {...browserRouterProps}
                className={clsx("full-width-landing-button", "multi-column", {
                  packages: type === "packages",
                })}
                message={
                  <Box className="landing-button-content-wrapper">
                    <Box className="packages-icons-wrapper">
                      <Icon name={IconName.HotelFunnelIcon} />
                      <Typography className="packages-icons-separator">
                        +
                      </Typography>
                      <Icon name={IconName.FlightFunnelIcon} />
                    </Box>

                    <p>Packages</p>

                    <Typography className="new-tag">New</Typography>
                  </Box>
                }
                disabled={false}
                onClick={() => browserRouterProps.history.push(PATH_PACKAGES)}
                ariaLabelText="Packages"
              ></ActionButtonWithRoute>
            )}
          />
        )}
      </Box>
      <Route
        render={(browserRouterProps: RouteComponentProps) => (
          <ActionButtonWithRoute
            {...browserRouterProps}
            className={clsx("full-width-landing-button", "search-bar", {
              "redesign-v3-enabled": isMobileHomeScreenVariant3,
            })}
            message={
              <>
                <Icon name={IconName.MagnifyingGlass} />
                <p>Search for {getSearchButtonText(type as LandingPageType)}</p>
              </>
            }
            disabled={false}
            onClick={() => browserRouterProps.history.push(`/${type}/search/`)}
            ariaLabelText={`Search for ${getSearchButtonText(
              type as LandingPageType
            )}`}
          ></ActionButtonWithRoute>
        )}
      />
    </>
  );
};

export default LandingPageNavigation;
